import { ShopperPurchaseResponse, SubscriberDetailsResponse } from '@hotmart/hub-api-client/types'
import { EVENT_ACTION, EVENT_ENTITY, sendDataHub } from '@hotmart/hub-components'
import { useAuthStore, useCommonEventDataStore, useLocales } from '@hotmart/hub-store'

export type Action = 'VIEW' | 'CLICK'

export type Target =
  | RegularizationThanksPageEventTarget
  | 'access-content'
  | 'alert-error-back-to-original-payment-type'
  | 'alter-method'
  | 'alter-method-credit-card-form'
  | 'automatize-payment'
  | 'back-previous-credit-card'
  | 'cancel-subscription-default'
  | 'cancel-subscription-enotas'
  | 'cancel-subscription-producer'
  | 'change-billing-date'
  | 'change-billing-date-failure'
  | 'change-billing-date-success'
  | 'change-payment-type-modal'
  | 'changed-billet-to-credit-card'
  | 'changed-credit-card'
  | 'changed-credit-card-error'
  | 'changed-hotmart-installments-billet-to-credit-card'
  | 'changed-hotmart-installments-pix-to-credit-card'
  | 'changed-pix-to-credit-card'
  | 'charge-available-card'
  | 'charge-available-modal'
  | 'charge-available-modal-content_access'
  | 'charge-available-modal-do-not-show-this-message_again'
  | 'charge-available-modal-start-payment'
  | 'choose-another-payment-method'
  | 'credit-card-payment-error'
  | 'delayed-alert'
  | 'generate-pix-code'
  | 'hotmart-installments-changed-credit-card'
  | 'keep-billet-like-payment-method'
  | 'keep-new-credit-card'
  | 'keep-pix-like-payment-method'
  | 'load-consumer-home'
  | 'load-consumer-my-purchases'
  | 'main-purchase-list'
  | 'modal-payment-not-possible-efecty'
  | 'modal-payment-not-possible-oxxo'
  | 'new-card-modal'
  | 'pay-with-pix'
  | 'payment-by-credit-card'
  | 'payment-management'
  | 'print-billet'
  | 'purchase-details'
  | 'purchase-details-payment-management'
  | 'purchase-details-payment-management-charge-alert'
  | 'purchase-details-payment-management-charge-available'
  | 'purchase-list'
  | 'purchase-list-content_access'
  | 'purchase-list-payment-management'
  | 'purchase-recurrence-retry'
  | 'purchase_payment'
  | 'recurrence-history'
  | 'redirect-alter-method'
  | 'redirect-payment-charge'
  | 'redirect-plan-exchange'
  | 'refund-charge'
  | 'register-new-credit-card'
  | 'regularize'
  | 'regularize-back'
  | 'regularize-by-billet'
  | 'regularize-by-credit-card'
  | 'regularize-by-efecty'
  | 'regularize-by-oxxo'
  | 'regularize-by-pix'
  | 'regularize-options'
  | 'regularize-with-pending-payment'
  | 'reprint_billet'
  | 'send-payment-charge'
  | 'thanks-page'
  | 'thanks-page-credit-card'
  | 'view-billet'
  | 'view-pix-code'

export type RegularizationThanksPageEventTarget =
  | 'copy-billet-code'
  | 'copy-pix-code'
  | 'show-billet-code'
  | 'show-pix-code'

export type InteractEvent = StartEvent & {
  isSmartInstallment: boolean
  paymentType: string
  subscriptionId: number
  utmSource?: string
  lastRecurrencyNumber?: number
  lastRecurrencyNumberDelayed?: number
}

export type StartEvent = {
  action: Action
  target: Target
  productId: number
  purchaseId: number
  status: string
  userId?: number
  locale?: string
  isMobile?: boolean
  isNewHub?: boolean
}

type PropInteractEvent = {
  action: Action
  target: Target
  purchaseStore: ShopperPurchaseResponse
  subscriberStore: SubscriberDetailsResponse
  metadata?: Array<{ key: string; value: string }>
  utmSource?: string
  lastRecurrencyNumber?: number
  lastRecurrencyNumberDelayed?: number
}

type PreFilledInteractEvent = PropInteractEvent & {
  locale: string
  userId: number
}

type PropStartEvent = {
  action: Action
  target: Target
  productId?: number
  purchaseId?: number
  status?: string
}

const isMobile = window?.matchMedia('(max-width: 992px)').matches

function mountInteractEvent({
  action,
  target,
  locale,
  userId,
  purchaseStore,
  subscriberStore,
  utmSource,
  lastRecurrencyNumber,
  lastRecurrencyNumberDelayed
}: PreFilledInteractEvent): InteractEvent {
  return {
    action,
    target,
    isMobile,
    locale,
    isNewHub: true,
    isSmartInstallment: purchaseStore?.purchase?.smartInstallment,
    paymentType: subscriberStore?.paymentType,
    productId: subscriberStore?.product?.id,
    userId: subscriberStore?.subscriber?.id || userId,
    subscriptionId: subscriberStore?.subscriptionId,
    purchaseId: purchaseStore?.purchase?.id,
    status: subscriberStore?.status,
    utmSource,
    lastRecurrencyNumber,
    lastRecurrencyNumberDelayed
  }
}

export default function useSendEventsSubscriberDatahub(): [
  (event: PropInteractEvent) => void,
  (event: PropStartEvent) => void
] {
  const { locale } = useLocales()
  const [UserAuthValue] = useAuthStore()
  const userId = Number(UserAuthValue?.profile?.id)
  const [commonEventData] = useCommonEventDataStore()
  const lastRecurrencyNumber = commonEventData?.lastRecurrencyNumber
  const lastRecurrencyNumberDelayed = commonEventData?.lastRecurrencyNumberDelayed

  function sendSubscriberInteractEvents({
    action,
    target,
    purchaseStore,
    subscriberStore,
    metadata,
    utmSource
  }: PropInteractEvent) {
    const utmSourceCommon = commonEventData?.utmSource ?? utmSource
    const baseEvent = mountInteractEvent({
      action,
      target,
      locale,
      userId,
      purchaseStore,
      subscriberStore,
      utmSource: utmSourceCommon,
      lastRecurrencyNumber,
      lastRecurrencyNumberDelayed
    })

    const event = metadata ? { ...baseEvent, metadata } : baseEvent

    sendDataHub(EVENT_ENTITY.PAYMENT_MANAGEMENT, EVENT_ACTION.INTERACT, '1.0', event)
  }

  function sendSubscriberStartEvents({ action, target, productId, purchaseId, status }: PropStartEvent) {
    const event = {
      action,
      target,
      locale,
      userId,
      productId,
      purchaseId,
      status,
      isMobile,
      isNewHub: true
    }

    sendDataHub(EVENT_ENTITY.PAYMENT_MANAGEMENT, EVENT_ACTION.START, '1.0', event)
  }

  return [sendSubscriberInteractEvents, sendSubscriberStartEvents]
}
