/* eslint-disable max-len */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type {
  PurchaseCommentMutation,
  HotPayInvoiceDetailMutation,
  SubscriptionsSettlePaymentPayload,
  ChangePaymentTypePayload,
  AlterPaymentMethodResponse,
  AlterPaymentCreditCardResponse,
  SendBrasilAsyncPaymentPayload,
  AlterBillingDatePayload
} from '../types'
import {
  postPurchaseComment,
  postPurchaseRating,
  postPutchaseLog,
  postProfilePhoto,
  getAddressByZipCode,
  getPurchaseDisplayLink,
  putProfileDocument,
  putProfile,
  putProfilePersonal,
  postProfilePassword,
  getPrivacyDataExport,
  deleteProfileDevice,
  getBecomeProducer,
  putCancelRefund,
  getFileDownload,
  getConfirmFileDownload,
  getInvoiceValidateData,
  getInvoiceDownloadData,
  getOpenInvoiceDownloadData,
  postUpgradeTicket,
  putInvoiceDetail,
  postArchive,
  deleteArchive,
  postPaymentRegularize,
  getInvoiceDetailByEmailAndTransaction,
  backToPreviousShopperCreditCard,
  postAbTest,
  changePaymentType,
  postAlterPaymentMethod,
  postAlterCreditCard,
  putSendNewPixToMail,
  putSendNewBilletToMail,
  putResendPixToMail,
  putNextBillingDate
} from './request'

export function usePurchaseCommentMutation() {
  const queryClient = useQueryClient()
  return useMutation((data: PurchaseCommentMutation) => void postPurchaseComment(data), {
    onSuccess: (data: PurchaseCommentMutation) => {
      queryClient.invalidateQueries(['purchase', 'comments', data.purchaseId])
    }
  })
}

export function usePurchaseRatingMutation() {
  const queryClient = useQueryClient()

  return useMutation((data: PurchaseCommentMutation) => void postPurchaseRating(data), {
    onSuccess: (data: PurchaseCommentMutation) => {
      queryClient.invalidateQueries(['purchase', 'ratings', data.purchaseId])
    }
  })
}

export function usePurchaseLogMutation() {
  return useMutation((purchaseId: string) => void postPutchaseLog(purchaseId))
}

export function useBecomeProducerMutation(configs) {
  return useMutation(getBecomeProducer, configs)
}

export function usePurchaseDisplayLinkMutation() {
  return useMutation((sellerId: number) => getPurchaseDisplayLink(sellerId))
}

export function useCancelRefundMutation() {
  return useMutation((purchaseId: number) => putCancelRefund(purchaseId))
}

export function useFileDownloadMutation() {
  return useMutation((fileParams: { purchaseId: number; contentId: number }) => getFileDownload(fileParams))
}

export function useConfirmFileDownloadMutation() {
  return useMutation((fileParams: { url: string; token: string }) => getConfirmFileDownload(fileParams))
}

export function useInvoiceValidateMutation() {
  return useMutation((fileParams: { email: string; transaction: string }) => getInvoiceValidateData(fileParams))
}

export function useOpenInvoiceDownloadMutation() {
  return useMutation((fileParams: { transaction: string; email: string }) => getOpenInvoiceDownloadData(fileParams))
}

export function useInvoiceDownloadMutation() {
  return useMutation((fileParams: { transaction: string }) => getInvoiceDownloadData(fileParams))
}

export function useUpgradeTicketMutation() {
  return useMutation((data: { previousPurchaseId: string; newPurchaseTransaction: string }) => postUpgradeTicket(data))
}

export function useInvoiceDetailMutation() {
  return useMutation((data: HotPayInvoiceDetailMutation) => putInvoiceDetail(data))
}

export function useInvoiceDetailByEmailAndTransactionMutation() {
  return useMutation((data: { transaction: string; email: string }) => {
    return getInvoiceDetailByEmailAndTransaction(data.transaction, data.email)
  })
}

export function useArchiveMutation() {
  const queryClient = useQueryClient()
  return useMutation(
    (data: { purchaseId?: number; productId: number; participTicket?: boolean }) => postArchive(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['purchases'] })
        queryClient.invalidateQueries({ queryKey: ['purchases-free'] })
      }
    }
  )
}

export function useUnarchiveMutation() {
  const queryClient = useQueryClient()
  return useMutation(
    (data: { purchaseId?: number; productId: number; participTicket?: boolean }) => deleteArchive(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['purchases'] })
        queryClient.invalidateQueries({ queryKey: ['purchases-free'] })
      }
    }
  )
}

export function useAbTestMutation() {
  return useMutation((data: { testId: string; transactionRef: string }) =>
    postAbTest(data?.testId, data?.transactionRef)
  )
}

/* *****************PROFILE **************** */

export function useProfilePhotoMutation() {
  const queryClient = useQueryClient()
  return useMutation((data: any) => postProfilePhoto(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['profile', 'user'])
    }
  })
}

export function useAddressZipCodeMutation() {
  return useMutation((zipCode: string) => getAddressByZipCode(zipCode))
}

export function useProfileDocumentMutation() {
  return useMutation((data: any) => putProfileDocument(data))
}

export function useProfiletMutation() {
  return useMutation((data: any) => putProfile(data))
}

export function useProfilePersonaltMutation() {
  return useMutation((data: any) => putProfilePersonal(data))
}

export function useProfilePasswordMutation() {
  return useMutation((data: any) => postProfilePassword(data))
}

export function usePrivacyDataExportMutation() {
  return useMutation(() => getPrivacyDataExport())
}

export function useProfileDeviceMutation() {
  return useMutation(() => deleteProfileDevice())
}

/* ***************** SUBSCRIBER **************** */
export function usePaymentRegularize() {
  return useMutation((params: SubscriptionsSettlePaymentPayload) => postPaymentRegularize(params))
}

export function useShopperCreditCard() {
  return useMutation((subscriptionId: number) => backToPreviousShopperCreditCard(subscriptionId))
}

export function useChangePaymentType() {
  return useMutation((payload: ChangePaymentTypePayload) => changePaymentType(payload))
}

export function useAlterPaymentMethod() {
  return useMutation((payload: AlterPaymentMethodResponse) => postAlterPaymentMethod(payload))
}

export function useAlterCreditCard() {
  return useMutation((payload: AlterPaymentCreditCardResponse) => postAlterCreditCard(payload))
}

export function useAlterBillingDate() {
  return useMutation((payload: AlterBillingDatePayload) => putNextBillingDate(payload))
}

export function useSendBillet() {
  return useMutation((payload: SendBrasilAsyncPaymentPayload) => putSendNewBilletToMail(payload))
}

export function useSendNewPix() {
  return useMutation((payload: SendBrasilAsyncPaymentPayload) => putSendNewPixToMail(payload))
}

export function useResendPix() {
  return useMutation((payload: SendBrasilAsyncPaymentPayload) => putResendPixToMail(payload))
}
