export function loadScript(url: string, scriptId: string) {
  const hasElement = Array.from(document.scripts).find((item) => item.src === url)

  return new Promise((resolve, reject) => {
    if (hasElement) {
      return resolve('script already exist')
    }

    const scriptElement = document.createElement('script')

    if (scriptId) {
      scriptElement.id = scriptId
    }

    scriptElement.src = url
    scriptElement.type = 'text/javascript'
    scriptElement.async = true
    scriptElement.defer = true
    scriptElement.onload = resolve
    scriptElement.onerror = reject

    document.head.appendChild(scriptElement)
  })
}
