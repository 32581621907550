import { useProductFilterSelectedStore } from '@hotmart/hub-store'

export default function useProductFilterChange() {
  const [, setFilterSelected] = useProductFilterSelectedStore()
  const setEmptyProductFilter = () => {
    setFilterSelected({ inputValue: '', orderBy: '', productFilters: [] })
  }

  return [setEmptyProductFilter]
}
