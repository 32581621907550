import { AxiosResponse } from 'axios'
import http from '../http'
import type {
  AlterBillingDatePayload,
  AlterPaymentCreditCardResponse,
  AlterPaymentMethodResponse,
  ChangePaymentTypePayload,
  DelayedPaymentsRequest,
  DelayedPaymentsResponse,
  EstimateNextChargesResponse,
  NextChargesResponse,
  SendBrasilAsyncPaymentPayload,
  SubscriberDetailsResponse,
  SubscriptionRecurrenceDetailsResponse,
  SubscriptionSettlePaymentResponse,
  SubscriptionsSettlePaymentPayload
} from '../../types'
import { hubV2Subscription, marketplacePurchaseSubscriber, subscriptionEngine } from './urls'

export const getSubscriberDetail = async (subscriptionId: number) => {
  return (await http.get<SubscriberDetailsResponse>(`${marketplacePurchaseSubscriber}/${subscriptionId}/details`)).data
}

export const getNextCharges = async (subscriptionId: number) => {
  return (
    await http.get<NextChargesResponse>(
      `${marketplacePurchaseSubscriber}/${subscriptionId}/payments/next?lastRecurrence=true`
    )
  ).data
}

export const getEstimateNextPaymentsQuery = async (subscriptionId: number, dateBase: number, dueDay: number) => {
  return (
    await http.get<EstimateNextChargesResponse>(
      // eslint-disable-next-line max-len
      `${marketplacePurchaseSubscriber}/subscription/${subscriptionId}/nextBillingDate?subscriptionId=${subscriptionId}&dateBase=${dateBase}&dueDay=${dueDay}`
    )
  ).data
}

export const getSubscriberDelayed = async ({ subscriptionId, options }: DelayedPaymentsRequest) => {
  return (
    await http.get<DelayedPaymentsResponse>(
      `${marketplacePurchaseSubscriber}/${subscriptionId}/payments/delayed?onlyLastRecurrence=${Boolean(
        options?.onlyLastRecurrence
      )}`
    )
  ).data
}

export const getSubscriberRecurrenceDetail = async (subscriptionId: number) => {
  return (
    await http.get<SubscriptionRecurrenceDetailsResponse[]>(
      `${marketplacePurchaseSubscriber}/${subscriptionId}/purchases`
    )
  ).data
}

export const postPaymentRegularize = async ({ subscriptionId, ...params }: SubscriptionsSettlePaymentPayload) => {
  type RequestBody = Omit<SubscriptionsSettlePaymentPayload, 'subscriptionId'>

  return http.post<
    SubscriptionSettlePaymentResponse,
    AxiosResponse<SubscriptionSettlePaymentResponse, RequestBody>,
    RequestBody
  >(`${marketplacePurchaseSubscriber}/${subscriptionId}/payments/settle`, { ...params })
}

export const backToPreviousShopperCreditCard = async (subscriptionId: number) => {
  return http.put(`${marketplacePurchaseSubscriber}/${subscriptionId}/creditCard/previous`)
}

export const changePaymentType = async (payload: ChangePaymentTypePayload) => {
  const { subscriptionId, newPaymentType } = payload
  return http.post(`${marketplacePurchaseSubscriber}/${subscriptionId}/payment/change`, { newPaymentType })
}

export const postAlterPaymentMethod = async (payload: AlterPaymentMethodResponse) => {
  return http.post(
    `${subscriptionEngine}/v1/subscription-engine/subscription/${payload.subscriptionId}/payment/change`,
    payload
  )
}

export const postAlterCreditCard = async (payload: AlterPaymentCreditCardResponse) => {
  return http.post(`${hubV2Subscription}/${payload.subscriptionId}/changeCreditCard`, payload)
}

export const putSendNewPixToMail = async (payload: SendBrasilAsyncPaymentPayload) => {
  const { subscriptionId, purchaseId } = payload
  return http.put(`${marketplacePurchaseSubscriber}/${subscriptionId}/purchases/${purchaseId}/new-pix`)
}

export const putResendPixToMail = async (payload: SendBrasilAsyncPaymentPayload) => {
  const { subscriptionId, purchaseId } = payload
  return http.put(`${marketplacePurchaseSubscriber}/${subscriptionId}/purchases/${purchaseId}/resend-pix`)
}

/** TODO: Execute get billet when a billet has been previously generated */
export const getGenerateBillet = async (payload: SendBrasilAsyncPaymentPayload) => {
  const { subscriptionId, purchaseId } = payload
  return (await http.get(`${marketplacePurchaseSubscriber}/${subscriptionId}/purchases/${purchaseId}/print-billet`))
    .data
}

export const putSendNewBilletToMail = async (payload: SendBrasilAsyncPaymentPayload) => {
  const { subscriptionId, purchaseId } = payload
  return http.put(`${marketplacePurchaseSubscriber}/${subscriptionId}/purchases/${purchaseId}/print-billet`)
}

export const putNextBillingDate = async (payload: AlterBillingDatePayload) => {
  const { subscriptionId, dueDay } = payload
  return http.put(`${marketplacePurchaseSubscriber}/${subscriptionId}/changePaymentDate/${dueDay}`, payload)
}
