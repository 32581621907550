import { useEffect, useState } from 'react'
import axios from 'axios'
import { useAuthStore } from '@hotmart/hub-store'
import { EXPIRED_TOKEN_MESSAGES, EventListenerName } from '@hotmart/hub-components'

const http = axios.create()

export function setToken(token?: string): void {
  if (token) {
    http.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

type Props = {
  children: React.ReactNode
}

const AxiosInterceptor = ({ children }: Props) => {
  const [isSet, setIsSet] = useState(false)
  const [UserAuthValue] = useAuthStore()
  const ucode = UserAuthValue?.profile?.ucode

  useEffect(() => {
    const interceptor = http.interceptors.response.use(
      (response) => response,

      async (error) => {
        const errorMessage =
          error.response?.data?.error_description ||
          error.response?.data?.message ||
          error.message ||
          'No error message'
        const responseUrl = error?.response?.request?.responseURL || error?.config?.url
        const status = error?.response?.status || error?.code

        if (status === 401) {
          const shouldTryToRenewToken = EXPIRED_TOKEN_MESSAGES.some((message) => errorMessage.includes(message))
          if (shouldTryToRenewToken) {
            window.dispatchEvent(
              new CustomEvent(EventListenerName.RENEW_USER_CAS, {
                detail: { ucode, origin: responseUrl, message: errorMessage }
              })
            )
            return Promise.reject(error)
          }

          window.dispatchEvent(
            new CustomEvent(EventListenerName.LOGOUT_USER_CAS, {
              detail: { ucode, origin: responseUrl, message: errorMessage }
            })
          )
        } else {
          window.dispatchEvent(
            new CustomEvent(EventListenerName.REQUEST_ERROR, {
              detail: { ucode, origin: responseUrl, message: `${status}_${errorMessage}` }
            })
          )
        }
        return Promise.reject(error)
      }
    )

    setIsSet(true)
    return () => http.interceptors.response.eject(interceptor)
  }, [ucode])

  return <>{isSet && children}</>
}
export default http
export { AxiosInterceptor }
