import { library, type IconName, type IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

import lightIcons from '../assets/font-icons/light'
import solidIcons from '../assets/font-icons/solid'

library.add(...lightIcons, ...solidIcons)

type IconsProps = Omit<FontAwesomeIconProps, 'icon'> & {
  className?: string
  dataTestId?: string
  iconName: IconName
  prefix: IconPrefix
  onClick?: () => void
}

const Icons = (props: IconsProps) => {
  return (
    <FontAwesomeIcon
      data-testid={props.dataTestId}
      className={props.className}
      onClick={props.onClick}
      icon={[props.prefix, props.iconName]}
      inverse={props.inverse}
      color={props.color}
      {...(props.size ? { size: props.size } : {})}
    />
  )
}
export default Icons
