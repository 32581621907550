import { type NavigateOptions, useLocation } from 'react-router-dom'

type Props = {
  eventOrigin: string
  optionsEventOriginToDataHubMetrics: NavigateOptions
}

export default function useEventOriginToDataHubMetrics(): Props {
  const location = useLocation()
  const eventOriginState = location.state?.eventOrigin?.replace('_', '-')

  return {
    eventOrigin: eventOriginState,
    optionsEventOriginToDataHubMetrics: { state: { eventOrigin: eventOriginState } }
  }
}
