import {
  faAlarmClock,
  faArrowDown,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRotateLeft,
  faArrowUpRight,
  faArrowUpRightFromSquare,
  faArrowsRotate,
  faBadgeDollar,
  faBadgePercent,
  faBarcodeRead,
  faBarsFilter,
  faBoxArchive,
  faBoxOpen,
  faCalendarDay,
  faCalendarXmark,
  faCamera,
  faCartShopping,
  faCartXmark,
  faChartLineUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleDollar,
  faCirclePlay,
  faCircleQuestion,
  faCircleStop,
  faClock,
  faCopy,
  faCreditCard,
  faDownload,
  faEnvelope,
  faFile,
  faFileInvoiceDollar,
  faGrid2,
  faHandHoldingBox,
  faHandHoldingDollar,
  faHouseBlank,
  faHyphen,
  faInfoCircle,
  faLocationDot,
  faLockKeyhole,
  faMagnifyingGlass,
  faMicrophone,
  faMobile,
  faMoneyCheckDollar,
  faPenToSquare,
  faQrcode,
  faRightLeft,
  faRocket,
  faRotate,
  faThumbsUp,
  faTrash,
  faTriangleExclamation,
  faTruckFast,
  faUser,
  faUserShield,
  faWarning,
  faXmark,
  faXmarkLarge
} from '@fortawesome/pro-light-svg-icons'

const lightIcons = [
  faAlarmClock,
  faArrowDown,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRotateLeft,
  faArrowUpRight,
  faArrowUpRightFromSquare,
  faArrowsRotate,
  faBadgeDollar,
  faBadgePercent,
  faBarcodeRead,
  faBarsFilter,
  faBoxArchive,
  faBoxOpen,
  faCalendarDay,
  faCalendarXmark,
  faCalendarXmark,
  faCamera,
  faCartShopping,
  faCartXmark,
  faChartLineUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleDollar,
  faCirclePlay,
  faCircleQuestion,
  faCircleStop,
  faCircleStop,
  faClock,
  faCopy,
  faCreditCard,
  faDownload,
  faEnvelope,
  faFile,
  faFileInvoiceDollar,
  faGrid2,
  faHandHoldingBox,
  faHandHoldingBox,
  faHandHoldingDollar,
  faHouseBlank,
  faHyphen,
  faInfoCircle,
  faLocationDot,
  faLockKeyhole,
  faMagnifyingGlass,
  faMicrophone,
  faMobile,
  faMoneyCheckDollar,
  faPenToSquare,
  faQrcode,
  faRightLeft,
  faRocket,
  faRotate,
  faThumbsUp,
  faTrash,
  faTriangleExclamation,
  faTruckFast,
  faUser,
  faUserShield,
  faWarning,
  faXmark,
  faXmarkLarge
]

export default lightIcons
