import { useEffect } from 'react'

const CUSTOM_EVENT_LISTENER = {
  START_MODAL_BY_CUSTOM_EVENT: 'START_MODAL_BY_CUSTOM_EVENT',
  FINISH_MODAL_BY_CUSTOM_EVENT: 'FINISH_MODAL_BY_CUSTOM_EVENT'
} as const

type UseModalByCustomEventProps<T> = {
  handlerOnCreate?: (event: CustomEvent<T>) => void
  handlerOnDestroy?: (event: CustomEvent<T>) => void
}

function useModalByCustomEvent<T>({ handlerOnCreate, handlerOnDestroy }: UseModalByCustomEventProps<T>) {
  const { START_MODAL_BY_CUSTOM_EVENT, FINISH_MODAL_BY_CUSTOM_EVENT } = CUSTOM_EVENT_LISTENER

  useEffect(() => {
    window.addEventListener(START_MODAL_BY_CUSTOM_EVENT, handlerOnCreate)
    window.addEventListener(FINISH_MODAL_BY_CUSTOM_EVENT, handlerOnDestroy)

    return () => {
      window.removeEventListener(START_MODAL_BY_CUSTOM_EVENT, handlerOnCreate)
      window.removeEventListener(FINISH_MODAL_BY_CUSTOM_EVENT, handlerOnDestroy)
    }
  }, [FINISH_MODAL_BY_CUSTOM_EVENT, START_MODAL_BY_CUSTOM_EVENT, handlerOnDestroy, handlerOnCreate])

  function onCreateModal(detail: T) {
    const event = new CustomEvent<T>(START_MODAL_BY_CUSTOM_EVENT, { detail })

    window.dispatchEvent(event)
  }

  function onDestroyModal(detail: T) {
    const event = new CustomEvent<T>(FINISH_MODAL_BY_CUSTOM_EVENT, { detail })

    window.dispatchEvent(event)
  }

  return {
    onCreateModal,
    onDestroyModal
  }
}

export default useModalByCustomEvent
