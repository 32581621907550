export const APIServers = {
  hub: process.env.API_SERVERS_HUB,
  refunds: process.env.API_SERVERS_REFUNDS,
  marketplace: process.env.API_SERVERS_MARKETPLACE,
  cep: process.env.API_SERVERS_CEP,
  accounts: process.env.API_SERVERS_ACCOUNTS,
  security: process.env.API_SERVERS_SECURITY,
  ask: process.env.API_SERVERS_ASK,
  space: process.env.API_SERVERS_SPACE,
  bkfSupport: process.env.API_SERVERS_BKF_SUPPORT,
  hotpayInvoice: process.env.API_SERVERS_HOTPAY_INVOICE,
  abTest: process.env.API_SERVERS_ABTEST,
  eticket: process.env.API_SERVERS_ETICKET,
  content_search: process.env.API_SERVERS_CONTENT_SEARCH,
  shipments: process.env.API_SERVERS_SHIPMENTS,
  hotHub: process.env.API_SERVERS_HOT_HUB,
  webhookAccess: process.env.API_SERVERS_WEBHOOK_ACCESS
}

export const API = {
  hub: process.env.API_HUB_V1,
  hubV2: process.env.API_HUB_V2,
  hubV3: process.env.API_HUB_V3,
  marketplaceUserProfile: process.env.API_MARKETPLACE_USER_PROFILE,
  marketplacePurchase: process.env.API_MARKETPLACE_PURCHASE,
  cep: process.env.API_CEP,
  document: process.env.API_DOCUMENT,
  security: process.env.API_SECURITY,
  features: process.env.API_FEATURES,
  dataExport: process.env.API_DATA_EXPORT,
  ask: process.env.API_ASK,
  space: process.env.API_SPACE,
  hotpayInvoice: process.env.API_HOTPAY_INVOICE,
  abTest: process.env.API_ABTEST,
  eticket: process.env.API_ETICKET,
  content_search: process.env.API_CONTENT_SEARCH,
  shipmentsV1: process.env.API_SHIPMENTS_V1,
  subscriptionEngine: process.env.API_SUBSCRIPTION_ENGINE,
  spaceV1: process.env.API_SPACE_V1,
  spaceV2: process.env.API_SPACE_V2,
  hotHub: process.env.API_HOT_HUB
}
