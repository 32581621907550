import { useState, useEffect } from 'react'

const COSMOS_SM_BREAKPOINT_IN_PX = 767
const COSMOS_MD_BREAKPOINT__IN_PX = 768
const COSMOS_LG_BREAKPOINT_IN_PX = 1199
const COSMOS_XL_BREAKPOINT_IN_PX = 1200

function getMediaQuery(query: string) {
  return window?.matchMedia(query)
}

const useDeviceType = () => {
  const getDeviceType = () => {
    const isMobile = getMediaQuery(`(max-width: ${COSMOS_SM_BREAKPOINT_IN_PX}px)`).matches
    const isTablet = getMediaQuery(
      `(min-width: ${COSMOS_MD_BREAKPOINT__IN_PX}px) and (max-width: ${COSMOS_LG_BREAKPOINT_IN_PX}px)`
    ).matches
    const isDesktop = getMediaQuery(`(min-width: ${COSMOS_XL_BREAKPOINT_IN_PX}px)`).matches

    return { isMobile, isTablet, isDesktop }
  }

  const [device, setDevice] = useState(getDeviceType())

  useEffect(() => {
    const handleResize = () => setDevice(getDeviceType())

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return device
}

export default useDeviceType
