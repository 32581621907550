import React from 'react'
import { Link } from 'react-router-dom'

import useEventOriginToDataHubMetrics from '@hotmart/hub-payments-subscriptions/src/utils/hooks/useEventOriginToDataHubMetrics'

import '@hotmart/cosmos/dist/breadcrumb/breadcrumb.css'

import './style.scss'

type Props = {
  history: Array<{
    active: boolean
    href: string
    name: string
    onClick?(): void
  }>
}

const Breadcrumb = ({ history }: Props) => {
  const { eventOrigin, optionsEventOriginToDataHubMetrics } = useEventOriginToDataHubMetrics()

  return (
    <hot-breadcrumb>
      {history.map((item) => {
        const { active, href, name } = item

        const attributes = {
          ...(active ? { active: '' } : {})
        }

        return (
          <hot-breadcrumb-item key={name} {...attributes}>
            {!active && href ? (
              <Link to={href} onClick={item.onClick} {...(eventOrigin ? optionsEventOriginToDataHubMetrics : {})}>
                {name}
              </Link>
            ) : (
              name
            )}
          </hot-breadcrumb-item>
        )
      })}
    </hot-breadcrumb>
  )
}

export default Breadcrumb
