import { HotmartRum } from '@hot-observability-js/react'

const useDispatchSentryError = () => {
  const dispatchSentryError = (message: Error) => {
    HotmartRum.captureException(message)
  }

  return [dispatchSentryError]
}

export default useDispatchSentryError
