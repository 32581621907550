import { atom } from 'recoil'

export interface CommonEventData {
  utmSource?: string
  lastRecurrencyNumber?: number
  lastRecurrencyNumberDelayed?: number
}

const CommonEventData = atom<CommonEventData>({
  key: 'CommonEventData',
  default: null
})

export default CommonEventData
