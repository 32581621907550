import { useQuery, useQueries } from '@tanstack/react-query'

import type { AccessPayload, DelayedPaymentsRequest } from '../types'

import {
  getPurchaseCanceledList,
  getPurchaseDetailV2,
  getPurchaseDownloadLink,
  getPurchaseList,
  getPurchaseListFreeSignup,
  getPurchaseContent,
  getPurchaseComments,
  getPurchaseRatings,
  getPurchaseBonus,
  getProfilePersonal,
  getCountry,
  getState,
  getCity,
  getCountryCode,
  getProfileUser,
  getProfileDocument,
  getPrivacyFeatures,
  getRefundProcess,
  getBusinessModelData,
  postAbTest,
  getUpgradeTicket,
  getPurchaseDetail,
  getInvoiceDetail,
  getProducerRankingBN,
  getRecommendedProducts,
  getShipmentTransactionDetail,
  getAvailableProduct,
  getPurchaseListV1,
  getAccess,
  postAccessFallback,
  getUserDetail
} from './request'
import {
  getEstimateNextPaymentsQuery,
  getNextCharges,
  getSubscriberDelayed,
  getSubscriberDetail,
  getSubscriberRecurrenceDetail
} from './requests/subscriber'

export function usePurchaseListQuery(enabled: boolean, archived?: string) {
  return useQuery(['purchases', archived], () => getPurchaseList(archived), { enabled: enabled })
}

export function usePurchaseListV1Query(enabled: boolean, archived?: string) {
  return useQuery(['purchases', 'detail_v1', archived], () => getPurchaseListV1(archived), { enabled: enabled })
}

export function usePurchaseListFreeQuery(archived?: string) {
  return useQuery(['purchases-free', archived], () => getPurchaseListFreeSignup(archived))
}

export function usePurchaseListCanceledQuery() {
  return useQuery(['purchases-canceled'], () => getPurchaseCanceledList())
}

export function usePurchaseDetailV2Query(productId: string | number) {
  return useQuery(['purchase', 'detail_v2', productId], () => getPurchaseDetailV2(productId), {
    enabled: !!productId
  })
}

export function usePurchaseDownloadLinkQuery(purchaseId: string, contentId: string) {
  return useQuery(['purchase', 'download', purchaseId, contentId], () => {
    return getPurchaseDownloadLink(purchaseId, contentId)
  })
}

export function usePurchaseContentQuery(purchases: Array<{ id?: number }>) {
  return useQueries({
    queries: purchases.map((item) => {
      return {
        queryKey: ['user', item.id],
        queryFn: () => getPurchaseContent(item.id)
      }
    })
  })
}

export function usePurchaseCommentsQuery(purchaseId: string) {
  return useQuery(['purchase', 'comments', purchaseId], () => getPurchaseComments(purchaseId))
}

export function usePurchaseRatingsQuery(productId: string) {
  return useQuery(['purchase', 'ratings', productId], () => getPurchaseRatings(productId))
}

export function usePurchaseBonusQuery(purchaseId: string) {
  return useQuery(['purchase', 'bonus', purchaseId], () => getPurchaseBonus(purchaseId))
}

export function useRefundProcessQuery(purchaseId: number) {
  return useQuery(['refund', 'process'], () => getRefundProcess(purchaseId))
}

export function useBusinessModelDataQuery(transactionCode: string) {
  return useQuery(['business', 'data'], () => getBusinessModelData(transactionCode))
}

export function useAbTestQuery(testId: string, transactionRef: string) {
  return useQuery(['abTest', 'execute'], () => postAbTest(testId, transactionRef))
}

export function useUpgradeTicketQuery(purchaseId: string) {
  return useQuery(['ticket', 'upgrade'], () => getUpgradeTicket(purchaseId))
}

export function useInvoiceDetailQuery(ucode: string, transaction: string) {
  return useQuery(['invoice', 'detail'], () => getInvoiceDetail(ucode, transaction))
}

export function useProcuderRankingBNQuery() {
  return useQuery(['producer', 'ranking'], () => getProducerRankingBN())
}

export function useRecommendedProducts(locale: string, ucode: string) {
  return useQuery(['data'], () => getRecommendedProducts(locale, ucode))
}

export function useShipmentTransactionDetailQuery(transactionCode: string, isEnabled: boolean) {
  return useQuery(['shipment', 'transaction', transactionCode], () => getShipmentTransactionDetail(transactionCode), {
    enabled: isEnabled
  })
}

export function useAvailableProduct() {
  return useQuery(['available', 'product'], () => getAvailableProduct())
}

/* ***************** ACCESS **************** */

export function useAccessQuery(data: AccessPayload) {
  return useQuery(['access'], () => getAccess(data))
}

export function useAccessFallbackQuery(data: AccessPayload, enabled: boolean) {
  return useQuery(['access', 'fallback'], () => postAccessFallback(data), { enabled })
}

/* *****************GENERAL **************** */

export function useUserDetailQuery() {
  return useQuery(['user'], () => getUserDetail())
}

/* *****************PROFILE **************** */

export function useProfileUserQuery() {
  return useQuery(['profile', 'user'], () => getProfileUser(), { enabled: false })
}

export function useAddressCountryQuery() {
  return useQuery(['address', 'country'], () => getCountry(), { enabled: false })
}

export function useAddressStateQuery(countryId: string) {
  return useQuery(['address', 'state'], () => getState(countryId), { enabled: false })
}

export function useAddressCityQuery(state: string) {
  return useQuery(['address', 'city'], () => getCity(state), { enabled: false })
}

export function useAddressCountryCodeQuery(countryId: string) {
  return useQuery(['address', 'country_code'], () => getCountryCode(countryId))
}

export function useProfilePersonalQuery() {
  return useQuery(['profile', 'personal'], () => getProfilePersonal(), { enabled: false })
}

export function useProfileDocumentQuery() {
  return useQuery(['profile', 'document'], () => getProfileDocument())
}

export function usePrivacyFeaturesQuery() {
  return useQuery(['profile', 'features'], () => getPrivacyFeatures())
}

/* ***************** PAYMENT/SUBSCRIPTION **************** */
export function usePurchaseDetailQuery(purchaseId: string) {
  return useQuery(['purchase', 'detail', purchaseId], () => getPurchaseDetail(purchaseId))
}

export function useSubscriberDetailQuery(subscriptionId: number) {
  return useQuery(['subscriber', 'detail', subscriptionId], () => getSubscriberDetail(subscriptionId), {
    enabled: !!subscriptionId
  })
}

export function useSubscriberRecurrenceDetailQuery(subscriptionId: number, { onlyAllowRefetch = false } = {}) {
  return useQuery(['subscriber', 'recurrences', subscriptionId], () => getSubscriberRecurrenceDetail(subscriptionId), {
    enabled: !!subscriptionId && !onlyAllowRefetch
  })
}

export function useSubscriberDelayedQuery({ subscriptionId, options }: DelayedPaymentsRequest) {
  return useQuery(
    ['subscriber', 'delayed', subscriptionId],
    () => {
      return getSubscriberDelayed({
        subscriptionId,
        options
      })
    },
    {
      enabled: !!subscriptionId
    }
  )
}

export function useNextPaymentsQuery(subscriptionId: number) {
  return useQuery(['list', 'charges', subscriptionId], () => getNextCharges(subscriptionId), {
    enabled: !!subscriptionId
  })
}
export function useEstimateNextPaymentsQuery(subscriptionId: number, dateBase: number, dueDay: number) {
  // eslint-disable-next-line max-len, prettier/prettier
  return useQuery(['subscriber', 'next-payments-date', subscriptionId, dateBase, dueDay], () => getEstimateNextPaymentsQuery(subscriptionId, dateBase, dueDay)
  )
}
