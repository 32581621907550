import React, { Component } from 'react'
import { HotmartRum } from '@hot-observability-js/react'
import ErrorGeneric from './ErrorGeneric'

export interface Props {
  sentryTag: { name: string; release: string }
  children: React.ReactNode
  isModal?: boolean
  userId?: number
}

class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
    error: null
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error) {
    HotmartRum.captureException(error)
    console.warn(error)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorGeneric isModal={this.props?.isModal} />
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
