export const EXPIRED_TOKEN_MESSAGES = ['Expired JWT', 'expired_accessToken', 'Resources not found']

export enum AuthStatus {
  AUTHENTICATED = 'AUTHENTICATED',
  AUTHENTICATING = 'AUTHENTICATING',
  RENEWING = 'RENEWING',
  LOGOUT_USER = 'LOGOUT_USER'
}

export enum EventListenerName {
  LOGOUT_USER_CAS = 'LOGOUT_USER_CAS',
  RENEW_USER_CAS = 'RENEW_USER_CAS',
  LOAD_USER_CAS = 'LOAD_USER_CAS',
  RENEW_ERROR_CAS = 'RENEW_ERROR_CAS',
  REQUEST_ERROR = 'REQUEST_ERROR'
}
