import React, { type ReactNode } from 'react'
import { clsx } from 'clsx'

import '@hotmart/cosmos/dist/form/form.css'
import '@hotmart/cosmos/dist/form/input-custom.css'
import '@hotmart/cosmos/dist/form/input-checkbox.css'

import HelperText from '../HelperText'

type Props = {
  id?: string
  label?: string | ReactNode
  checked?: boolean
  error?: string
  helperText?: string
  disabled?: boolean
  className?: string
  inputClassName?: string
  labelClassName?: string
  onChange?: () => void
}

const Checkbox = React.forwardRef(
  (
    { id, label, checked, error, helperText, disabled, className, inputClassName, labelClassName, onChange }: Props,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const classNames = clsx(['hot-form hot-form--custom hot-form--checkbox', className])
    const inputClassNames = clsx(['hot-form__input', inputClassName])
    const attributes = {
      ...(error ? { invalid: '' } : {}),
      ...(disabled ? { disabled: true } : {}),
      ...(checked ? { checked: true } : { checked: false })
    }

    return (
      <div className={classNames}>
        <input ref={ref} type="checkbox" className={inputClassNames} id={id} onChange={onChange} {...attributes} />
        <label className="hot-form__label" htmlFor={id}>
          <span className={labelClassName}>{label}</span>
        </label>
        <HelperText error={error} helperText={helperText} />
      </div>
    )
  }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
